// Games Table Styles
.table-responsive {
  border-radius: $border-radius;
  box-shadow: $box-shadow-sm;
  background: $white;
}

.table {
  margin-bottom: 0;

  thead th {
    font-size: $font-size-sm;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding: $spacer;
    background: $gray-100;
    border-bottom: 2px solid $gray-200;
    vertical-align: middle;

    &.games-column {
      min-width: 200px;
    }

    &.text-center {
      text-align: center;
    }
  }

  tbody {
    tr {
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        background-color: $gray-100;
        transform: translateY(-1px);
      }

      td {
        padding: $spacer;
        vertical-align: middle;
      }

      &.no-games-row {
        cursor: default;

        &:hover {
          background-color: transparent;
          transform: none;
        }

        .no-games-content {
          padding: 2rem 0;

          .icon-wrapper {
            width: 64px;
            height: 64px;
            margin: 0 auto;
            background: $gray-100;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
              font-size: 1.5rem;
              color: $gray-500;
            }
          }

          h3 {
            color: $gray-700;
            font-weight: 500;
            font-size: 1.25rem;
          }

          p {
            font-size: $font-size-sm;
          }
        }
      }
    }
  }
}

// Games Grid Styles (Mobile)
.games-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: $grid-gutter-width;
  padding: calc($grid-gutter-width * 0.5);

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  .game-card-wrapper {
    height: 100%;
  }
}

// No Games Card
.no-games-card {
  border: none;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow-sm;
  background: $white;

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
  }

  i {
    color: $gray-400;
  }

  h3 {
    color: $gray-600;
    font-weight: 500;
  }
}
