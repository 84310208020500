/* Modern Card Styles */
.modern-card {
  border: none;
  border-radius: 15px;
  background: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: 100%;
  min-height: 140px;
}

.modern-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
}

.modern-card .card-body {
  padding: 1.5rem;
}

/* Typography */
.modern-card .card-title {
  font-size: 0.8rem;
  letter-spacing: 0.05em;
  margin-bottom: 0.75rem;
  color: #8898aa;
}

.modern-card .card-value {
  font-size: 1.75rem;
  font-weight: 600;
  color: #32325d;
  display: block;
  line-height: 1.2;
}

/* Icon Styles */
.modern-card .icon-container {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background: linear-gradient(45deg, #5e72e4, #825ee4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.25rem;
  box-shadow: 0 4px 12px rgba(94, 114, 228, 0.3);
}

/* Stats Indicator */
.stats-indicator {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.stats-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.35rem 0.75rem;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 600;
  color: white;
  gap: 0.5rem;
}

.stats-badge i {
  font-size: 0.75rem;
}

.stats-label {
  color: #8898aa;
  font-size: 0.875rem;
  text-transform: capitalize;
}

/* Spread Values */
.spread-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.spread-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.spread-value .team-name {
  font-size: 1rem;
  font-weight: 600;
  color: #32325d;
}

.spread-value .point-value {
  font-size: 1rem;
  font-weight: 700;
  color: #5e72e4;
}

/* Score Values */
.score-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 0.5rem;
}

.score-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.score-value:last-child {
  border-bottom: none;
}

.score-value .team-name {
  font-size: 1rem;
  font-weight: 600;
  color: #32325d;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.score-value .score-number {
  font-size: 1.25rem;
  font-weight: 700;
  color: #2dce89;
  min-width: 40px;
  text-align: right;
}

/* Responsive Adjustments */
@media (min-width: 769px) and (max-width: 991px) {
  .modern-card {
    min-height: 130px;
  }

  .modern-card .card-value {
    font-size: 1.6rem;
  }

  .modern-card .icon-container {
    width: 44px;
    height: 44px;
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .modern-card {
    min-height: 120px;
  }

  .modern-card .card-value {
    font-size: 1.5rem;
  }

  .modern-card .icon-container {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }

  .score-value .team-name {
    font-size: 0.9rem;
  }

  .score-value .score-number {
    font-size: 1.1rem;
  }
}
